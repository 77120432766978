import React, { useEffect, useState } from "react";
import { getFloorStats } from "../../api/stats";
import { Checkbox, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { Link } from "@vkontakte/vkui";
import { $spinner } from "../../model/spinner";
import { Td, Th } from "./stats";
import { togglePassedTask } from "../../api/users";

export const Floor4Table = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    $spinner.show();
    getFloorStats(4)
      .then((res) => setRows(res))
      .finally(() => $spinner.hide());
  };

  const togglePassed = async (userId) => {
    await togglePassedTask(userId);
    await fetchData();
  };

  return (
    <TableContainer component={Paper}>
      {rows.length > 0 && (
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <Th>Пользователь</Th>
              <Th className="center">Направление</Th>
              <Th className="center">Презентация</Th>
              <Th className="center">Сдано</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, key) => {
              return (
                <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <Td scope="row">
                    <Link target="_blank" href={`https://vk.com/id${row.vk_id}`}>
                      {row.first_name} {row.last_name}
                    </Link>
                  </Td>
                  <Td className="center">{row.direction?.name ? row.direction?.name : "-"}</Td>
                  <Td className="center">
                    {row.direction?.filePath ? (
                      <Link target="_blank" href={`/api/stats/${row.id}/downloadFile`}>
                        скачать
                      </Link>
                    ) : (
                      "-"
                    )}
                  </Td>
                  <Td className="center">
                    {row.direction?.filePath ? (
                      <Checkbox size="small" onChange={() => togglePassed(row.id)} checked={row.task_passed} />
                    ) : (
                      "-"
                    )}
                  </Td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

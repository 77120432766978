import { GET, POST, sleep } from "./call";

export async function getUsers() {
  await sleep();
  return GET("/admin/users");
}

export async function createUser(link) {
  return POST("/admin/users/create", { link: link });
}

export async function deleteUser(id) {
  await sleep();
  return POST("/admin/users/delete", { vk_id: id });
}

export async function togglePassedTask(userId) {
  await sleep();
  return POST(`/admin/users/togglePassedTask`, { userId: userId });
}

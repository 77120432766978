import { $window } from "../model/window";
import { isVkApp } from "./appParams";

function getIsMobile() {
  // return window.innerWidth < 500;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const copyToClipboard = (content) => {
  const el = document.createElement("textarea");
  el.value = content;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

//для айфонов с челкой
export function getIsIphone10AndAbove() {
  return $window.innerHeight >= 780 && ["iPhone Simulator", "iPhone"].includes(navigator.platform);
}

export const elastic = (value) => {
  if (isVkApp) {
    return `calc(100vh / ${maketHeight / value})`;
  } else {
    return `${$window.innerHeight / (maketHeight / value)}px`;
  }
};

export const isInstagramBrowser = window.navigator.userAgent.toLowerCase().includes("instagram");
export const isIos =
  window.navigator.userAgent.toLowerCase().includes("iphone") ||
  window.navigator.userAgent.toLowerCase().includes("ios");

export const isMobile = getIsMobile();
export const isIphone10AndAbove = getIsIphone10AndAbove();
export const maketHeight = 812;

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
    return baseURL;
  });
};

import { GET, POST, sleep } from "./call";

export async function getAdmins() {
  await sleep();
  return GET("/admin/admins");
}

export async function createAdmin(vk_id, comment) {
  await sleep();
  return POST("/admin/admins/create", { id: vk_id, comment: comment });
}

export async function deleteAdmin(id) {
  await sleep();
  return POST("/admin/admins/delete", { id: id });
}

import React, { useState } from "react";
import { $popouts } from "model/popouts";
import { Button, FormItem, Group, Header, Input, Panel } from "@vkontakte/vkui";
import { MyPanelHeader } from "ui/panelHeader";
import { Layout } from "ui/layout";
import { userId } from "lib/appParams";
import { deleteUser } from "api/users";

export const Welcome = () => {
  const [vkId, setVkId] = useState("");

  const onDeleteClicked = () => {
    deleteUser(vkId).then((res) => {
      $popouts.showSnackBar(res);
    });
  };

  return (
    <Panel id="welcome">
      <MyPanelHeader>Главная</MyPanelHeader>
      <Layout>
        <Group
          header={
            <Header>
              Удаление пользователя и сброс действий из <strong>основного приложения</strong>
            </Header>
          }
          description="Внимание! Действие нельзя будет отменить"
        >
          <FormItem top="VK ID">
            <Input value={vkId} onChange={(e) => setVkId(e.target.value)} type="number" />
          </FormItem>
          <FormItem style={{ paddingTop: 0, paddingBottom: 0 }} onClick={() => setVkId(userId)}>
            <div style={{ fontSize: "12px", color: "#2688eb", cursor: "pointer" }}>Подставить свой id</div>
          </FormItem>
          <FormItem>
            <Button disabled={vkId.length < 3} onClick={onDeleteClicked} size="m" appearance="negative">
              Удалить
            </Button>
          </FormItem>
        </Group>
      </Layout>
    </Panel>
  );
};

import { flow, types } from "mobx-state-tree";
import { init } from "api/auth";

export const $user = types
  .model({
    user: types.frozen({}),
    access: types.optional(types.boolean, false),
    inited: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    init: flow(function* () {
      try {
        self.user = yield init().then((res) => res.vk_user);
        console.log("$user", self.user);
      } catch (error) {
        console.error("init error: ", error);
      }
    }),
    setAccess(value) {
      self.access = value;
      self.inited = true;
    },
  }))
  .create();

import { types } from "mobx-state-tree";

export const $alert = types
  .model({
    visible: types.optional(types.boolean, false),
    message: types.optional(types.string, "Что-то пошло не так, попробуйте позже"),
    header: types.optional(types.string, ""),
  })
  .actions((self) => ({
    show(message, header) {
      self.visible = true;
      self.message = message;
      self.header = header;
    },
    hide() {
      self.visible = false;
    },
  }))
  .create();

import { types } from "mobx-state-tree";

export const $popouts = types
  .model({
    curPopoutParams: types.maybeNull(types.frozen()),
  })
  .actions((self) => ({
    showSnackBar(text) {
      self.curPopoutParams = { text };
    },
    close() {
      self.curPopoutParams = null;
    },
  }))
  .create({ curPopoutParams: null });

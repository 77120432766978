import React from "react";
import { ModalRoot } from "@vkontakte/vkui";
import { useLocation } from "@happysanta/router";
import { router, MODAL_ADD_ADMIN, MODAL_ADD_USER } from "model/router";
import { AddAdminModal } from "./modals/AddAdminModal";
import { AddUserModal } from "./modals/AddUserModal";

export const Modals = () => {
  const location = useLocation();
  const onClose = () => router.replaceModal(null);

  return (
    <ModalRoot activeModal={location.getModalId()}>
      <AddAdminModal id={MODAL_ADD_ADMIN} onClose={onClose} />
      <AddUserModal id={MODAL_ADD_USER} onClose={onClose} />
    </ModalRoot>
  );
};

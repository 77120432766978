import React from "react";
import { $alert } from "model/alert";
import { $actions } from "model/actions";
import { ActionSheet, Alert, ActionSheetItem } from "@vkontakte/vkui";
import { PAGE_WELCOME, router } from "./model/router";

export function getPopouts() {
  // alerts
  if ($alert.visible) {
    return (
      <Alert
        onClose={() => $alert.hide()}
        actions={[
          {
            title: "Ок",
            mode: "cancel",
            action: () => {
              $alert.hide();
            },
          },
        ]}
        actionsLayout="vertical"
        header={$alert.header || "Ошибка"}
        text={$alert.message}
      />
    );
  }

  // action sheet
  if ($actions.actionItems) {
    return (
      <ActionSheet
        onClose={() => $actions.hide()}
        iosCloseItem={
          <ActionSheetItem autoclose mode="cancel">
            Отменить
          </ActionSheetItem>
        }
      >
        {$actions.actionItems.node}
      </ActionSheet>
    );
  }

  return null;
}

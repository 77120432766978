import React from "react";
import { useLocation } from "@happysanta/router";
import { Root, ScreenSpinner, View, PanelHeader } from "@vkontakte/vkui";
import { observer } from "mobx-react-lite";
import { PANEL_ADMINS, PANEL_STATS, PANEL_USERS, PANEL_WELCOME, VIEW_MAIN } from "./model/router";
import { $spinner } from "./model/spinner";
import { Welcome } from "./pages/welcome";
import { Modals } from "./modals";
import { SnackbarCont } from "./snackbarCont";
import { getPopouts } from "./popouts";
import { $user } from "./model/user";
import { Admins } from "./pages/admins";
import { ToastContainer } from "react-toastify";
import { $scheme } from "model/scheme";
import vkBridge from "@vkontakte/vk-bridge";
import { Users } from "./pages/users";
import { Stats } from "./pages/stats";

export const App = observer(() => {
  const location = useLocation();
  const activePanel = location.getViewActivePanel(VIEW_MAIN);

  vkBridge.subscribe((e) => {
    if (e.detail.type === "VKWebAppUpdateConfig") $scheme.setVkUi(e.detail.data.scheme);
    if (e.detail.type === "VKWebAppViewRestore") $scheme.setBarStyle();
  });

  if (!$user.access) return <PanelHeader>ACCESS DENIED ⛔</PanelHeader>;

  return (
    <>
      <ToastContainer />
      <SnackbarCont />
      <Root popout={getPopouts()} activeView={VIEW_MAIN} modal={<Modals />}>
        <View id={VIEW_MAIN} activePanel={activePanel} popout={$spinner.active && <ScreenSpinner />}>
          <Welcome id={PANEL_WELCOME} />
          <Admins id={PANEL_ADMINS} />
          <Users id={PANEL_USERS} />
          <Stats id={PANEL_STATS} />
        </View>
      </Root>
    </>
  );
});

import { Button, Div, FormItem, Input, ModalCard, ModalPage, ModalPageHeader } from "@vkontakte/vkui";
import React, { useState } from "react";
import { Icon56UserAddOutline } from "@vkontakte/icons";
import { createAdmin } from "../api/admins";
import { $admins } from "../model/admins";

export const AddAdminModal = ({ id, onClose }) => {
  const [vkId, setVkId] = useState("");
  const [comment, setComment] = useState("");

  const AddUser = () => {
    createAdmin(vkId, comment).then((res) => {
      $admins.load();
      onClose();
    });
  };

  return (
    <ModalPage dynamicContentHeight id={id} header={<ModalPageHeader>Новый админ</ModalPageHeader>}>
      <FormItem top="*VK ID">
        <Input onChange={(e) => setVkId(e.target.value)} type="number" />
      </FormItem>
      <FormItem top="COMMENT">
        <Input onChange={(e) => setComment(e.target.value)} type="text" />
      </FormItem>
      <Div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={AddUser} size="l" mode="primary" key="button">
          Создать
        </Button>
      </Div>
    </ModalPage>
  );
};

import React from "react";
import { PanelHeader, PanelHeaderButton } from "@vkontakte/vkui";
import { Icon24MenuOutline } from "@vkontakte/icons";
import { $window } from "model/window";
import { isMobile } from "lib/features";
import styled from "styled-components";

const PanelHeaderSt = styled(PanelHeader)`
  z-index: 100 !important;
`;

export const MyPanelHeader = ({ children }) => {
  return (
    <PanelHeaderSt
      shadow
      separator={false}
      left={
        isMobile && (
          <PanelHeaderButton>
            <Icon24MenuOutline onClick={() => $window.toggleMenuVisible()} />
          </PanelHeaderButton>
        )
      }
    >
      {children}
    </PanelHeaderSt>
  );
};

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Open Sans";
    font-weight: 700;
  }

  @font-face {
    font-family: "Open Sans";
    font-weight: 400;
  }
`;

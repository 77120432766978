import { Button, Div, FormItem, Input, ModalPage, ModalPageHeader } from "@vkontakte/vkui";
import React, { useState } from "react";
import { $users } from "../model/users";
import { createUser } from "../api/users";

export const AddUserModal = ({ id, onClose }) => {
  const [link, setLink] = useState("");

  const submit = () => {
    createUser(link).then((res) => {
      $users.load();
      onClose();
    });
  };

  return (
    <ModalPage dynamicContentHeight id={id} header={<ModalPageHeader>Новый пользователь</ModalPageHeader>}>
      <FormItem top="Ссылка на страницу (https://vk.com/durov)">
        <Input onChange={(e) => setLink(e.target.value)} type="text" />
      </FormItem>
      <Div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={submit} size="l" mode="primary" key="button">
          Создать
        </Button>
      </Div>
    </ModalPage>
  );
};

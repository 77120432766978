import { types } from "mobx-state-tree";
import { getInitialHashParams } from "lib/appParams";

export const $debug = types
  .model({
    isEnabled: types.optional(types.boolean, true),
    messages: types.optional(types.array(types.string), ["logs start " + new Date().toTimeString()]),
    isTabAvailable: types.optional(types.boolean, getInitialHashParams().debug === "1"),
  })
  .actions((self) => ({
    log(message) {
      self.messages.push(message);
    },
    setEnabled() {
      self.isEnabled = true;
    },
  }))
  .create();

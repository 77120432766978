import { flow, types } from "mobx-state-tree";
import { getUsers } from "../api/users";

export const $users = types
  .model({
    list: types.frozen([]),
    statistic: types.frozen([]),
  })
  .actions((self) => ({
    load: flow(function* () {
      try {
        self.list = yield getUsers().then((res) => res);
      } catch (error) {
        console.error("get users error: ", error);
        throw error;
      }
    }),
    setStatistic(value) {
      self.statistic = value;
    },
  }))
  .create();

import React, { useEffect, useState } from "react";
import { getFloorStats } from "../../api/stats";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Link } from "@vkontakte/vkui";
import { $spinner } from "../../model/spinner";
import { Td, Th } from "./stats";

export const Floor1Table = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    $spinner.show();
    getFloorStats(1)
      .then((res) => setRows(res))
      .finally(() => $spinner.hide());
  }, []);

  return (
    <TableContainer component={Paper}>
      {rows.length > 0 && (
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <Th>Пользователь</Th>
              <Th>Данные участника</Th>
              <Th>Данные представителя</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, key) => {
              return (
                <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <Td scope="row">
                    <Link target="_blank" href={`https://vk.com/id${row.vk_id}`}>
                      {row.first_name} {row.last_name}
                    </Link>
                  </Td>
                  <Td>
                    {row.form?.name} {row.form?.last_name}
                    <br />
                    {row.form?.birthdate}
                  </Td>
                  <Td>
                    {row.form?.parent_name} {row.form?.parent_last_name}
                    <br />
                    {row.form?.parent_birthdate}
                    <br />
                    {row.form?.parent_phone}
                  </Td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

import bridge from "@vkontakte/vk-bridge";
import { ANALYTICS_EVENTS, sendAnalytics } from "lib/analytics";
import { init } from "./api/auth";
import { $user } from "./model/user";
import { $admins } from "./model/admins";
import { $users } from "./model/users";

sendAnalytics(ANALYTICS_EVENTS.appStarted);

async function initRequest() {
  await init();
  await $user.setAccess(true);
  await $admins.load();
  await $users.load();

  bridge.send("VKWebAppInit");
}

initRequest();

import React, { useEffect, useState } from "react";
import { getFloorStats } from "../../api/stats";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Link } from "@vkontakte/vkui";
import { $spinner } from "../../model/spinner";
import { Td, Th } from "./stats";

export const Floor2Table = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    $spinner.show();
    getFloorStats(2)
      .then((res) => setRows(res))
      .finally(() => $spinner.hide());
  }, []);

  const [showMore, setShowMore] = useState(null);

  return (
    <TableContainer component={Paper}>
      {rows.length > 0 && (
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <Th>Пользователь</Th>
              <Th>Мини-эссе</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, key) => {
              return (
                <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <Td scope="row">
                    <Link target="_blank" href={`https://vk.com/id${row.vk_id}`}>
                      {row.first_name} {row.last_name}
                    </Link>
                  </Td>
                  <Td>
                    {showMore === row.id ? row.video_feedback?.text : row.video_feedback?.text.substring(0, 70)}

                    {row.video_feedback?.text.length > 50 && (
                      <>
                        {" "}
                        <Link onClick={() => setShowMore(row.id === showMore ? null : row.id)}>
                          {showMore === row.id ? "Скрыть" : "Показать"}
                        </Link>
                      </>
                    )}
                  </Td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

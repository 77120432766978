import React, { useEffect, useState } from "react";
import { getFloorStats } from "../../api/stats";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Link } from "@vkontakte/vkui";
import { $spinner } from "../../model/spinner";
import styled from "styled-components";
import { Td, Th } from "./stats";

export const Floor3Table = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    $spinner.show();
    getFloorStats(3)
      .then((res) => setRows(res))
      .finally(() => $spinner.hide());
  }, []);

  return (
    <TableContainer component={Paper}>
      {rows.length > 0 && (
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <Th>Пользователь</Th>
              <Th className="center">Маркетинг</Th>
              <Th className="center">Видеозвонки</Th>
              <Th className="center">Искуственный интеллект</Th>
              <Th className="center">Проектный менеджмент</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, key) => {
              return (
                <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <Td scope="row">
                    <Link target="_blank" href={`https://vk.com/id${row.vk_id}`}>
                      {row.first_name} {row.last_name}
                    </Link>
                  </Td>
                  <Td className="center">
                    {row.passedTest && row.passedTest[1]
                      ? `${row.passedTest[1].correct}/${row.passedTest[1].all}`
                      : "-"}
                  </Td>
                  <Td className="center">
                    {row.passedTest && row.passedTest[2]
                      ? `${row.passedTest[2].correct}/${row.passedTest[2].all}`
                      : "-"}
                  </Td>
                  <Td className="center">
                    {row.passedTest && row.passedTest[3]
                      ? `${row.passedTest[3].correct}/${row.passedTest[3].all}`
                      : "-"}
                  </Td>
                  <Td className="center">
                    {row.passedTest && row.passedTest[4]
                      ? `${row.passedTest[4].correct}/${row.passedTest[4].all}`
                      : "-"}
                  </Td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

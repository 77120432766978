import { Div, SplitLayout } from "@vkontakte/vkui";
import React from "react";
import { isMobile } from "lib/features";
import { Menu } from "./menu";

export const Layout = ({ children }) => {
  return (
    <Div>
      <SplitLayout style={{ marginTop: "16px" }}>
        <Menu />
        <div style={{ width: "100%", marginLeft: isMobile ? 0 : 20 }}>{children}</div>
      </SplitLayout>
    </Div>
  );
};

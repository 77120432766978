const reduceHandler = (acc, item, i) => {
  if (i === 0 && !item.includes("=")) return { ...acc, shortId: parseInt(item, 10), shortValue: item };
  const [key, value] = decodeURIComponent(item).split("=");
  return key ? { ...acc, [key]: value } : acc;
};

export const queryParams = window.location.search.replace("?", "").split("&").reduce(reduceHandler, {});

export const getCurrentHashParams = () => window.location.hash.replace("#", "").split("&").reduce(reduceHandler, {});

let savedHashParams = window.location.hash;
export const getInitialHashParams = () => savedHashParams.replace("#", "").split("&").reduce(reduceHandler, {});

export const isVkApp = !!queryParams.vk_app_id;

export const appId = parseInt(queryParams.vk_app_id, 10);
export const userId = parseInt(queryParams.vk_user_id, 10);

import { types } from "mobx-state-tree";

export const $window = types
  .model({
    innerHeight: window.innerHeight,
    isMenuVisible: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setInnerHeight(value) {
      self.innerHeight = value;
    },
    toggleMenuVisible() {
      self.isMenuVisible = !self.isMenuVisible;
    },
  }))
  .create();

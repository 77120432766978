import React, { useEffect, useState } from "react";
import { getFloorStats } from "../../api/stats";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { Link } from "@vkontakte/vkui";
import { $spinner } from "../../model/spinner";
import styled from "styled-components";
import { Icon12Help } from "@vkontakte/icons";
import { Th } from "./stats";

const Td = styled(TableCell)`
  line-height: 20px !important;
`;

const questions = [
  "1. Насколько тебе понравилось участвовать в учебной практике?",
  "2. Что тебе понравилось в учебной практике больше всего? Напиши до трёх положительных результатов от этой практики для тебя. ",
  "3. Напиши до трёх негативных факторов в учебной практике, которые стоит учесть и исправить её организаторам в будущем. ",
];

const ThText = ({ num }) => {
  return (
    <div style={{ display: "flex" }}>
      <div>ответ {num}</div>
      <Tooltip title={questions[num - 1]}>
        <div style={{ margin: "3px 8px", cursor: "pointer" }}>
          <Icon12Help style={{ color: "#8a8a8a" }} />
        </div>
      </Tooltip>
    </div>
  );
};

export const Floor5Table = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    $spinner.show();
    getFloorStats(5)
      .then((res) => setRows(res))
      .finally(() => $spinner.hide());
  }, []);

  return (
    <TableContainer component={Paper}>
      {rows.length > 0 && (
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <Th>Пользователь</Th>
              <Th>
                <ThText num={1} />
              </Th>
              <Th>
                <ThText num={2} />
              </Th>
              <Th>
                <ThText num={3} />
              </Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, key) => {
              return (
                <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <Td scope="row">
                    <Link target="_blank" href={`https://vk.com/id${row.vk_id}`}>
                      {row.first_name} {row.last_name}
                    </Link>
                  </Td>
                  <Td>{row.interview?.question_1}</Td>
                  <Td>{row.interview?.question_2}</Td>
                  <Td>{row.interview?.question_3}</Td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

import React from "react";
import ReactDOM from "react-dom";
import "lib/appParams";
import { AdaptivityProvider, AppRoot, ConfigProvider } from "@vkontakte/vkui";
import vkBridge from "@vkontakte/vk-bridge";
import { RouterContext } from "@happysanta/router";
import { App } from "./app";
import { router } from "model/router";
import "@vkontakte/vkui/dist/vkui.css";
import "./init";
import "./app.scss";
import { GlobalStyles } from "./globalStyles";
import { $scheme } from "./model/scheme";
import { observer } from "mobx-react-lite";

setTimeout(() => {
  vkBridge.send("VKWebAppInit");

  if (document.getElementById("preloader")) {
    document.getElementById("preloader").style.display = "none";
  }

  document.getElementById("root").style.opacity = "1";
  document.getElementById("root").style.transition = "opacity 0.7s ease-in-out";
}, 50);

export const ConfigProviderObserved = observer(() => {
  return (
    <ConfigProvider>
      <AdaptivityProvider>
        <AppRoot>
          <GlobalStyles />
          <App />
        </AppRoot>
      </AdaptivityProvider>
    </ConfigProvider>
  );
});

ReactDOM.render(
  <React.StrictMode>
    <RouterContext.Provider value={router}>
      <ConfigProviderObserved />
    </RouterContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

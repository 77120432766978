import { Page, Router } from "@happysanta/router";
import { ANALYTICS_EVENTS, sendAnalytics } from "lib/analytics";

// PAGES
export const PAGE_WELCOME = "/";
export const PAGE_ADMINS = "/admins";
export const PAGE_USERS = "/users";
export const PAGE_STATS = "/stats";

// HOME (view and panels)
export const PANEL_WELCOME = "PANEL_WELCOME";
export const PANEL_ADMINS = "PANEL_ADMINS";
export const PANEL_USERS = "PANEL_USERS";
export const PANEL_STATS = "PANEL_STATS";

// TESTS (view and panels)
export const VIEW_MAIN = "VIEW_MAIN";

export const routes = {
  // home
  [PAGE_WELCOME]: new Page(PANEL_WELCOME, VIEW_MAIN),
  [PAGE_ADMINS]: new Page(PANEL_ADMINS, VIEW_MAIN),
  [PAGE_USERS]: new Page(PANEL_USERS, VIEW_MAIN),
  [PAGE_STATS]: new Page(PANEL_STATS, VIEW_MAIN),
};

export const MODAL_ADD_ADMIN = "MODAL_ADD_ADMIN";
export const MODAL_ADD_USER = "MODAL_ADD_USER";

export const router = new Router(routes);

router.on("update", (route) => {
  const { pageId, params } = route;
  let path = pageId.replace(/\(.*?\)/g, "");
  Object.entries(params).forEach(([paramName, paramValue]) => {
    path = path.replace(`:${paramName}`, paramValue);
  });

  sendAnalytics({ ...ANALYTICS_EVENTS.pageOpened, label: path });
});

router.start();

import { GET, sleep } from "./call";

export async function getStats() {
  await sleep();
  return GET("/admin/stats");
}

export async function getFloorStats(id) {
  await sleep();
  return GET(`/admin/stats/${id}`);
}

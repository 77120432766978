import React, { useEffect, useState } from "react";
import { getStats } from "../../api/stats";
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Link } from "@vkontakte/vkui";
import { $spinner } from "../../model/spinner";
import styled from "styled-components";
import { Icon20CheckBoxOn } from "@vkontakte/icons";
import { Icon20CheckBoxOff } from "@vkontakte/icons";

export const Th = styled(TableCell)`
  line-height: 18px !important;
  width: 90px;
  background: rgba(236, 236, 236, 0.56);
  height: 40px;

  &.center {
    text-align: center;
  }
`;

export const Td = styled(TableCell)`
  line-height: 20px !important;

  &.center {
    text-align: center;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px 0;
  color: #3f8ae0;
`;

export const StatsTable = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    $spinner.show();
    getStats()
      .then((res) => {
        setRows(res);
      })
      .finally(() => $spinner.hide());
  }, []);

  return (
    <TableContainer component={Paper}>
      {rows.length > 0 && (
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <Th>Пользователь</Th>
              <Th className="center">Форма соглашения</Th>
              <Th className="center">Мини-эссе</Th>
              <Th className="center">Тесты</Th>
              <Th className="center">Дипломная работа</Th>
              <Th className="center">Сертификат</Th>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, key) => {
              return (
                <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <Td scope="row">
                    <Link target="_blank" href={`https://vk.com/id${row.vk_id}`}>
                      {row.first_name} {row.last_name}
                    </Link>
                  </Td>
                  <Td className="center">
                    <IconWrapper>{row.form_exists ? <Icon20CheckBoxOn /> : <Icon20CheckBoxOff />}</IconWrapper>
                  </Td>
                  <Td className="center">
                    <IconWrapper>
                      {row.video_feedback_exists ? <Icon20CheckBoxOn /> : <Icon20CheckBoxOff />}
                    </IconWrapper>
                  </Td>
                  <Td className="center">
                    <IconWrapper>{row.tests_exists ? <Icon20CheckBoxOn /> : <Icon20CheckBoxOff />}</IconWrapper>
                  </Td>
                  <Td className="center">
                    <IconWrapper>{row.final_test_exists ? <Icon20CheckBoxOn /> : <Icon20CheckBoxOff />}</IconWrapper>
                  </Td>
                  <Td className="center">
                    <IconWrapper>{row.got_cert_exists ? <Icon20CheckBoxOn /> : <Icon20CheckBoxOff />}</IconWrapper>
                  </Td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

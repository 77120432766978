import { types } from "mobx-state-tree";

export const $actions = types
  .model({
    actionItems: types.frozen(null),
  })
  .actions((self) => ({
    show(actionItems) {
      self.actionItems = { node: actionItems };
    },
    hide() {
      self.actionItems = null;
    },
  }))
  .create({ actionItems: null });

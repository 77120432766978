import { types } from "mobx-state-tree";
import vkBridge from "@vkontakte/vk-bridge";
import { PANEL_WELCOME, router } from "./router";

export const $scheme = types
  .model({
    vkUi: types.optional(types.string, ""),
    statusBarStyle: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setVkUi(value) {
      self.vkUi = value;
      self.setBarStyle();
    },
    setBarStyle() {
      const currentPanelId = router.getCurrentLocation().getPanelId();

      //условие где фон статус бара будет не зависеть от выбранной темы в vk
      if (currentPanelId === PANEL_WELCOME) {
        vkBridge.send("VKWebAppSetViewSettings", {
          status_bar_style: "light",
          action_bar_color: "#FF3347", // параметр для андроида
          // navigation_bar_color: "#FFF", // виртуальные кнопки у андройда
        });
      } else {
        vkBridge.send("VKWebAppSetViewSettings", {
          status_bar_style: self.vkUi === "space_gray" ? "light" : "dark",
          action_bar_color: self.vkUi === "space_gray" ? "#19191a" : "#FFF", // параметр для андроида
          // navigation_bar_color: self.vkUi === "space_gray" ? "#FFF" : "#191919",  // виртуальные кнопки у андройда
        });
      }
    },
  }))
  .create();

import { queryParams } from "lib/appParams";
import { getConfig } from "lib/config";

const { metrikaId } = getConfig();
// console.info({ metrikaId });

const userId = queryParams.vk_user_id ? queryParams.vk_user_id : "unknown";
window.ym(metrikaId, "setUserID", userId);

const printToConsole = false;

export const ANALYTICS_EVENTS = {
  appStarted: {
    category: "app",
    action: "started",
    title: "Приложение запущено",
  },
  pageOpened: {
    category: "page",
    action: "opened",
    title: "Открыта страница",
  },
  photoUploaded: {
    category: "photo",
    action: "uploaded",
    title: "Загрузил фото",
  },
  lookSelected: {
    category: "look",
    action: "selected",
    title: "Выбрал любой образ",
    labels: {
      look1: "Выбрал 1 образ",
      look2: "Выбрал 2 образ",
      look3: "Выбрал 3 образ",
      look4: "Выбрал 4 образ",
    },
  },
  shareClicked: {
    category: "sharing",
    action: "clicked",
    title: "Нажал поделиться",
  },
  sharedStory: {
    category: "sharing",
    action: "shared",
    title: "Поделился в истории",
  },
  visitFromStory: {
    category: "visit",
    action: "visitFromStory",
    title: "Пришел в приложение из сторис",
  },
  photoDownloaded: {
    category: "photo",
    action: "photoDownloaded",
    title: "Скачал фото",
  },
  shopClicked: {
    category: "shop",
    action: "clicked",
    title: "Кликнул на любую кнопку магазина",
    labels: {
      ozon: "Кликнул на 'OZON'",
      goldapple: "Кликнул на 'ЗОЛОТОЕ ЯБЛОКО'",
    },
  },
  tryonAgainClicked: {
    category: "look",
    action: "tryonAgainClicked",
    title: "Кликнул на 'Примерить еще раз'",
  },
  // clickDetailedRules: {
  //   category: "link",
  //   action: "clickDetailedRules",
  //   title: "Клик на подробные правила",
  // },
};

function processPageView({ label }) {
  const pageUrl = `/page${label}`;
  window.gtag("event", "page_view", {
    page_title: pageUrl,
    page_location: pageUrl,
    page_path: pageUrl,
  });

  window.ym(metrikaId, "hit", label);

  if (printToConsole) console.info("sent", pageUrl);
}

function processEvent({ category, action, label }) {
  const pageUrl = `/event/${category}/${action}`;
  window.gtag("event", "page_view", {
    page_title: pageUrl,
    page_location: pageUrl,
    page_path: pageUrl,
  });
  window.ym(metrikaId, "reachGoal", pageUrl);
  if (printToConsole) console.info("sent", pageUrl);

  if (label) {
    const pageUrlWithLabel = `/event/${category}/${action}/${label}`;
    window.gtag("event", "page_view", {
      page_title: pageUrlWithLabel,
      page_location: pageUrlWithLabel,
      page_path: pageUrlWithLabel,
    });
    window.ym(metrikaId, "reachGoal", pageUrlWithLabel);
    if (printToConsole) console.info("sent", pageUrlWithLabel);
  }
}

export function sendAnalytics({ category, action, label }) {
  if (category === "page" && action === "opened") processPageView({ category, action, label });
  else processEvent({ category, action, label });
}

// для вставки в гугл док
let events = [];
// Object.values(ANALYTICS_EVENTS).map((data) => {
//   events.push(`/event/${data.category}/${data.action}\t${data.title}\n`);
//   data.labels &&
//     Object.keys(data.labels).map((key) => {
//       events.push(`/event/${data.category}/${data.action}/${key}\t${data.labels[key]} \n`);
//     });
// });
// console.info(events.join(""));

import React, { useEffect } from "react";
import { MODAL_ADD_USER, router } from "model/router";
import { Button, Cell, Group, Link, List, Panel, Div } from "@vkontakte/vkui";
import { MyPanelHeader } from "ui/panelHeader";
import { Layout } from "ui/layout";
import { Icon16Add, Icon28DeleteOutline } from "@vkontakte/icons";
import { $users } from "model/users";
import { observer } from "mobx-react-lite";
import { deleteUser } from "api/users";
import { TextTooltip } from "@vkontakte/vkui/dist/cssm/components/TextTooltip/TextTooltip";
import { Alert } from "@mui/material";

export const Users = observer(() => {
  const onAddClicked = () => {
    router.pushModal(MODAL_ADD_USER);
  };

  const onDeleteClicked = (id) => {
    deleteUser(id).then(() => loadItems());
  };

  const loadItems = () => {
    $users.load();
  };

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <Panel id="users">
      <MyPanelHeader>Пользователи</MyPanelHeader>
      <Layout>
        <Group>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={onAddClicked} size="m" before={<Icon16Add />}>
              Добавить
            </Button>
            <div style={{ padding: "6px" }}>Всего пользователей: {$users.list.length}</div>
          </div>
        </Group>
        <Group>
          <Alert sx={{ mb: 1 }} severity="info">
            Эти пользователи имеют доступ к <strong>основному</strong> приложению
          </Alert>
          {$users.list.length > 0 ? (
            <List>
              {$users.list.map((el) => {
                return (
                  <Cell
                    key={el.id}
                    after={
                      <Icon28DeleteOutline
                        style={{ color: el.root ? "#ccc" : "" }}
                        onClick={() => !el.root && onDeleteClicked(el.vk_id)}
                      />
                    }
                  >
                    <div>
                      <Link href={`https://vk.com/id${el.vk_id}`} target="_blank">
                        {el.first_name} {el.last_name}
                      </Link>
                    </div>
                    <div>создан: {el.created_at}</div>
                  </Cell>
                );
              })}
            </List>
          ) : (
            "Нет записей"
          )}
        </Group>
      </Layout>
    </Panel>
  );
});

import React from "react";
import { Cell, Group } from "@vkontakte/vkui";
import { PAGE_ADMINS, PAGE_WELCOME, PAGE_USERS, router, PAGE_STATS } from "model/router";
import { isMobile } from "lib/features";
import styled from "styled-components";
import { useLocation } from "@happysanta/router";
import { $window } from "model/window";
import { observer } from "mobx-react-lite";
import { Icon28Users3Outline, Icon28CrownOutline, Icon28HomeOutline, Icon28StatisticsOutline } from "@vkontakte/icons";

const MobileWrapper = styled.div`
  padding: 10px;
  top: 40px;
  box-sizing: border-box;
  position: fixed;
  background: #fff;
  z-index: 99;
  width: 100%;
  left: 0;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 3px 3px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 3px 3px 0px rgba(34, 60, 80, 0.2);
`;

export const Menu = observer(() => {
  const pageId = useLocation().getPageId();

  const isActive = (path) => {
    return pageId === path ? { backgroundColor: "var(--button_secondary_background)", borderRadius: 8 } : {};
  };

  const goToPage = (page) => {
    router.pushPage(page);
    $window.toggleMenuVisible();
  };

  const menu = (
    <Group>
      <Cell
        style={isActive("/")}
        onClick={() => goToPage(PAGE_WELCOME)}
        data-story="welcome"
        before={<Icon28HomeOutline />}
      >
        Главная
      </Cell>
      <Cell
        style={isActive("/admins")}
        onClick={() => goToPage(PAGE_ADMINS)}
        data-story="admins"
        before={<Icon28CrownOutline />}
      >
        Администраторы
      </Cell>
      <Cell
        style={isActive("/users")}
        onClick={() => goToPage(PAGE_USERS)}
        data-story="users"
        before={<Icon28Users3Outline />}
      >
        Пользователи
      </Cell>
      <Cell
        style={isActive("/stats")}
        onClick={() => goToPage(PAGE_STATS)}
        data-story="stats"
        before={<Icon28StatisticsOutline />}
      >
        Статистика
      </Cell>
    </Group>
  );

  if (isMobile) {
    return <MobileWrapper>{$window.isMenuVisible && menu}</MobileWrapper>;
  } else {
    return menu;
  }
});

import { flow, types } from "mobx-state-tree";
import { getAdmins } from "../api/admins";

export const $stats = types
  .model({
    selectedTab: types.optional(types.string, "stats"),
  })
  .actions((self) => ({
    load: flow(function* () {
      try {
        self.list = yield getAdmins().then((res) => res);
      } catch (error) {
        console.error("get admins error: ", error);
      }
    }),
    setTab(value) {
      self.selectedTab = value;
    },
  }))
  .create();

import React from "react";
import { Group, Panel, Tabs, TabsItem } from "@vkontakte/vkui";
import { MyPanelHeader } from "ui/panelHeader";
import { Layout } from "ui/layout";
import { observer } from "mobx-react-lite";
import { $stats } from "../../model/stats";
import { StatsTable } from "./stats";
import { Floor1Table } from "./1_floor";
import { Floor2Table } from "./2_floor";
import { Floor3Table } from "./3_floor";
import { Floor4Table } from "./4_floor";
import { Floor5Table } from "./5_floor";

const tabs = [
  {
    value: "stats",
    label: "Статистика",
  },
  {
    value: "floor1",
    label: "Форма согл.",
  },
  {
    value: "floor2",
    label: "Мини-эссе",
  },
  {
    value: "floor3",
    label: "Тесты",
  },
  {
    value: "floor4",
    label: "Итог. задание",
  },
  {
    value: "floor5",
    label: "Сертификат",
  },
];
export const Stats = observer(() => {
  return (
    <Panel id="stats">
      <MyPanelHeader>Статистика</MyPanelHeader>
      <Layout>
        <Group>
          <Tabs mode="buttons">
            {tabs.map((el, key) => (
              <TabsItem key={key} onClick={() => $stats.setTab(el.value)} selected={$stats.selectedTab === el.value}>
                {el.label}
              </TabsItem>
            ))}
          </Tabs>
        </Group>
        {$stats.selectedTab === "stats" && <StatsTable />}
        {$stats.selectedTab === "floor1" && <Floor1Table />}
        {$stats.selectedTab === "floor2" && <Floor2Table />}
        {$stats.selectedTab === "floor3" && <Floor3Table />}
        {$stats.selectedTab === "floor4" && <Floor4Table />}
        {$stats.selectedTab === "floor5" && <Floor5Table />}
      </Layout>
    </Panel>
  );
});
